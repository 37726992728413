module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true,"trackingId":"UA-153404397-1","cookieDomain":"swoof.me"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"619025408843786"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"swoof-markting-website","short_name":"Swoof","start_url":"/","background_color":"#f8f8f9","theme_color":"#f8f8f9","display":"minimal-ui","icon":"src/images/swoof-icon.png","theme_color_in_head":false,"cache_busting_mode":"query","include_favicon":true,"legacy":true,"cacheDigest":"ad69c623f3a2a3d021ae99dfb9234e6b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
